import tgpStore from '../tgpStore';
import VariantStatusModule from '../variantStatus';
const {VariantStatus} = VariantStatusModule;

class FamilyVariant {
  chrom: any;
  chromText: string;
  bp: any;
  pos: any;
  sampleIds: any;
  samples: any;
  family: any;
  individualDiagnoses: any;
  total_shared: any;
  params: any;
  variantId: string;
  sampleGenotypes: any;
  isLongRead: boolean;
  ref: any;
  alt: any;
  constructor(data: any, params: any, variantId: string) {
    this.params = params;
    this.variantId = variantId;
    this.chrom = data.chromosome;
    this.chromText = decodeChrom(this.chrom);
    this.bp = data.position;
    this.pos = data.position;  // store as 'bp', too, for convenience
    this.sampleIds = data.sample_guids.split(",");
    this.samples = this.sampleIds.map(s => tgpStore.findSampleById(s));
    this.isLongRead = this.samples[0].isLongRead;
    this.family = this.samples[0].patient.family;
    this.individualDiagnoses = this.samples.map(s => {
      return {
        diagnosis: 'primaryDiagnosis' in s.patient ?
          s.patient.primaryDiagnosis.name : "N/A",
        diagnosisCode: 'primaryDiagnosis' in s.patient ?
          s.patient.primaryDiagnosis.code : "N/A",
        individualId: s.patient.name
      }
    });
    if (data.total_shared) this.total_shared = data.total_shared;
  }    

  get anyOtherFamilyVariantStatusSets() {
    return Object.keys(this.otherFamilyVariantStatusSets).length > 0;
  }

  get isSolved() {
    return this.lastStatusFlag ? this.lastStatusFlag.isSolved : false;
  }
  
  get maxDiagnosis() {
    // find first patient with a primary diagnosis
    let samp = this.samples.find(s => s.patient.primaryDiagnosis);
    if (!samp) return null
    return {code: samp.patient.primaryDiagnosis.code,
      name: samp.patient.primaryDiagnosis.name}
  }

  get otherFamilyVariantStatusSets() {
    return tgpStore.filterVariantStatusSets(this.variantId, this.family.id); 
  }

  get statusFlags() {
    return tgpStore.findVariantStatusSet(this.variantId, this.family.id);
  }

  get lastStatusFlag() {
    return this.statusFlags ? this.statusFlags.activeVariantStatus : null;
  }

  get sampleNameText() {
    return this.sampleGenotypes
      .map(s => s.sample.name + "-" + s.sample.patient.name)
      .join(",");
  }

  addVariantStatus({variantStatusId, timestamp, userId, 
    firstName, lastName, solvedStatus, acmg, note, gene
  }) {
    const props = {
      id: variantStatusId, variantId: this.variantId, familyId:
      this.family.id, family: this.family, userId, firstName, lastName,
      timestamp, chrom: this.chrom, pos: this.pos, ref: this.ref, alt:
      this.alt, note, acmg, solvedStatus, gene
    };
    const variantStatus = new VariantStatus(props);
    this.family.addVariantStatus(variantStatus);
  }
}

function decodeChrom(chrom: number) {
  if (chrom < 23) return String(chrom);
  else if (chrom == 23) return "X";
  else if (chrom == 24) return "Y";
  else if (chrom == 25) return "M";
}

export default FamilyVariant;

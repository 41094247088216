import Papa from "papaparse";
import QueryResults from './queryResults';
import FamilySnv from './familyVariant/familySnv';
import FamilySv from './familyVariant/familySv';
import Rna from "./familyVariant/rna";


const parseConfig = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  delimiter: "\t"
}

function partitionResults(arr: any[]): [any[], any[]] {
  const rnaResults: any[] = [];
  const variantResults: any[] = [];
  // Checking for hasOwnProperty maintains backwards compatibility with
  // older versions of results prior to the addition of the RNA results
  arr.forEach(item => {
    const isRna = item.hasOwnProperty("tpm") && item.tpm !== null;
    (isRna ? rnaResults : variantResults).push(item);
  });
  return [rnaResults, variantResults];
}

function deserialize(queryId, response) {
  let [params, results] = response.split('|-|');
  params = JSON.parse(params);
  const parsed = Papa.parse(results, parseConfig);

  // Separate RNA data from variant data
  const [rnaData, variantData] = partitionResults(parsed.data);

  const famVars = variantData.map(d => {
    return d.sv_id || d.svid ? new FamilySv(d, params) : new FamilySnv(d, params);
  });

  // Create RNA objects and link them to variants
  const rnaEntries = rnaData.map(d => new Rna(d));
  famVars.forEach(variant => {
    variant.rna = rnaEntries.filter(rna => {
      const geneMatch = variant instanceof FamilySv
        ? variant.genes?.some(g => g.name === rna.gene)
        : variant.geneText === rna.gene;
      return geneMatch && variant.samples.some(s => s.patient === rna.sample.patient);
    });
  });

  return new QueryResults(queryId, famVars, params);
}

export default {
  deserialize: deserialize
};



import annot from '../../../../data/annotations.json';
import Sample from './sample';
import {Lab, Project} from './groups';
import {Diagnosis, DiagnosisGroup, Hpo} from '../diagnosisEntities';
import model from '../model';
import tgpStore from '../tgpStore';
import Family from './family';

function getPrimaryDiagnosis(diagnoses) {
  if (!diagnoses) return {name: null, code: null};
  let pd = diagnoses.find(d => d.id in model.primaryDiagnosisLookup);
  if (pd) return model.primaryDiagnosisLookup[pd.id];
  pd = diagnoses.find(d => d.source == 'Orphanet');
  if (!pd) pd = diagnoses[0];
  return {name: pd.name, code: pd.code};
}

function getDiagnosisGroups(primaryDiagnosis) {
  return model.diagnosisGroups
      .filter(dg => dg.primaryDiagnosisCodes.includes(primaryDiagnosis.code));
}

class Patient {
  id: any;
  matchId: string;
  family: Family;
  lab: Lab;
  name: any;
  genomicStoreId: any;
  hasLoginUserAdminAccess: boolean;
  hasNotes: boolean;
  notes: String;
  consanguinity: any;
  affection: any;
  affectionText: string;
  sex: any;
  sexText: string;
  ageOfOnset: string;
  superPopulation: any;
  modeOfInheritance: any;
  samples: Sample[];
  projects: Project[];
  diagnoses: Diagnosis[];
  primaryDiagnosis: any;
  diagnosisGroups: DiagnosisGroup[];
  rnaSamples: Sample[];
  hpos: Hpo[];
  mother: Patient | null;
  father: Patient | null;
  partner: Patient | null;
  children: Patient[];
  constructor(id, family: Family, lab: Lab, name, 
    genomicStoreId, matchId, affection, sex, ageOfOnset, superPopulation, consanguinity, 
    modeOfInheritance, hasNotes: boolean, notes: String, samples, projects, diagnoses, hpos
  ) {
    this.id = id;
    this.family = family;
    this.lab = lab;
    this.name = name;
    this.genomicStoreId = genomicStoreId;
    this.matchId = matchId;
    this.hasLoginUserAdminAccess = tgpStore.loginUser.adminLabs.includes(
      this.lab
    );
    this.affection = affection;
    this.affectionText = annot.affectionStatusTypes.enum.find(
      e => e.id == this.affection).name;
    this.sex = sex;
    this.sexText = annot.sex.enum.find(e => e.id == this.sex).name;
    this.consanguinity = consanguinity;
    this.ageOfOnset = ageOfOnset;
    this.superPopulation = superPopulation;
    this.modeOfInheritance = modeOfInheritance;
    this.hasNotes = hasNotes;
    this.notes = notes;
    this.samples = samples;
    this.projects = projects;
    this.diagnoses = diagnoses;
    this.primaryDiagnosis = getPrimaryDiagnosis(diagnoses);
    this.diagnosisGroups = getDiagnosisGroups(this.primaryDiagnosis);
    this.hpos = hpos;
    this.mother = null;
    this.father = null;
    this.partner = null;
    this.children = [];
  }

  get hasRnaSamples(): boolean {
    return this.getRnaSamples.length > 0;
  }

  get rnaSamplesText(): string {
    return this.getRnaSamples.map(s => s.experimentTypeText).join(', ');
  }

  get getRnaSamples(): Sample[] {
    return this.samples.filter(s => s.experimentTypeText.startsWith("RNA"));
  }

  // This feature was only necessary for shareLabs; Can be removed
  get labsWithAccess() { return [this.lab]; }

  get shareName() : string {
    return this.name || "P" + this.matchId;
  }

  addDiagnosis(id, source, code, name) {
    let diag = tgpStore.createOrRetrieveDiagnosis({id, source, code, name});
    if (!this.diagnoses) this.diagnoses = [diag];
    else this.diagnoses.push(diag);
    this.primaryDiagnosis = getPrimaryDiagnosis(this.diagnoses);
    this.diagnosisGroups = getDiagnosisGroups(this.primaryDiagnosis);
  }

  hasAnyDiagnosis(diagnosisFilters) {
    return !diagnosisFilters.length || 
      this.diagnoses && this.diagnoses.find(
        d => diagnosisFilters.includes(d)) ||
      this.hpos && this.hpos.find(
        h => diagnosisFilters.includes(h)) ||
      this.primaryDiagnosis.code && this.diagnosisGroups.find(
        dg => diagnosisFilters.includes(dg))
      ? true : false;
  }

  hasAnyMembership(membershipFilters) {
    return !membershipFilters.length ||
      membershipFilters.includes(this.lab) ||
      this.projects && this.projects.find(p => membershipFilters.includes(p));
  }

  hasAnyModeOfInheritance(inheritanceFilters) {
    return !inheritanceFilters.length ||
      inheritanceFilters.includes(this.modeOfInheritance);
  }

  removeDiagnosis(diagnosis) {
    this.diagnoses = this.diagnoses.filter(d => d != diagnosis);
    if (!this.diagnoses.length) this.diagnoses = null;
    tgpStore.removeDiagnosisIfUnused(diagnosis);
    this.primaryDiagnosis = getPrimaryDiagnosis(this.diagnoses);
    this.diagnosisGroups = getDiagnosisGroups(this.primaryDiagnosis);
  }

  addHpo(id, code, name, group) {
    let hpo = tgpStore.createOrRetrieveHpo({id, code, name, group});
    if (!this.hpos) this.hpos = [hpo];
    else this.hpos.push(hpo);
  }

  addProject(project: Project) {
    this.projects.push(project);
  }

  removeProject(project: Project) {
    const idx = this.projects.indexOf(project);
    this.projects.splice(idx, 1);
  }

  removeHpo(hpo) {
    this.hpos = this.hpos.filter(h => h != hpo);
    if (!this.hpos.length) this.hpos = null;
    tgpStore.removeHpoIfUnused(hpo);
  }

}

export default Patient;

import tgpStore from '../tgpStore';
import ShareModule from './share';
const { deserializeConcatString } = ShareModule;

const A3SS_A5SS_RI_SE_COLUMNS = ['longExonStart_0base', 'longExonEnd', 'shortES', 'shortEE', 'flankingES', 'flankingEE', 'IJC_SAMPLE_1', 'SJC_SAMPLE_1', 'IJC_SAMPLE_2', 'SJC_SAMPLE_2', 'IncFormLen', 'SkipFormLen', 'PValue', 'FDR', 'IncLevel1', 'IncLevel2', 'IncLevelDifference'];
const MXE_COLUMNS = ['1stExonStart_0base', '1stExonEnd', '2ndExonStart_0base', '2ndExonEnd', 'upstreamES', 'upstreamEE', 'downstreamES', 'downstreamEE', 'IJC_SAMPLE_1', 'SJC_SAMPLE_1', 'IJC_SAMPLE_2', 'SJC_SAMPLE_2', 'IncFormLen', 'SkipFormLen', 'PValue', 'FDR', 'IncLevel1', 'IncLevel2', 'IncLevelDifference'];

function parseSigData(sig_data_string: string, columnNames: string[]): any[] {
  if (!sig_data_string) {
    return [];
  }
  return sig_data_string.split('<>').map(row => {
    const values = deserializeConcatString(row, '@');
    const rowData = Object.fromEntries(
      columnNames.map((columnName, index) => [columnName, values[index]])
    );
    return rowData;
  });
}

class Rna {
  sample: any;
  gene: string;
  tpm: number;
  gtexMean: number;
  gtexStdev: number;
  gtex1p: number;
  gtexMin: number;
  a3ssSigData: any[];
  a5ssSigData: any[];
  mxeSigData: any[];
  riSigData: any[];
  seSigData: any[];
  constructor(data: any) {
    this.sample = tgpStore.findSampleById(data.sample_guids);
    this.gene = data.vep_gene;
    const sigDataFields = data.tpm.split('||');
    const tpm_fields = deserializeConcatString(sigDataFields[0], '@');
    [this.tpm, this.gtexMean, this.gtexStdev, this.gtex1p, this.gtexMin] = tpm_fields;
    this.a3ssSigData = parseSigData(sigDataFields[1], A3SS_A5SS_RI_SE_COLUMNS);
    this.a5ssSigData = parseSigData(sigDataFields[2], A3SS_A5SS_RI_SE_COLUMNS);
    this.mxeSigData = parseSigData(sigDataFields[3], MXE_COLUMNS);
    this.riSigData = parseSigData(sigDataFields[4], A3SS_A5SS_RI_SE_COLUMNS);
    this.seSigData = parseSigData(sigDataFields[5], A3SS_A5SS_RI_SE_COLUMNS);
  }

  get cellType() {
    const match = this.sample.experimentTypeText.match(/RNA-Seq \(([^)]+)\)/);
    return match[1];
  }
}

export default Rna;

import {Diagnosis, DiagnosisGroup, Hpo} from './diagnosisEntities';
import {Lab, LabMember, Project, ProjectMember} from './baseEntities/groups';
import { User } from "./baseEntities/user";
import Family from './baseEntities/family';
import Sample from './baseEntities/sample';
import Patient from './baseEntities/patient';
import model from './model';
import VariantStatusModule from './variantStatus';
import {Upload} from './baseEntities/upload';
const { VariantStatusSet } = VariantStatusModule;

class SolvedStatus {
  id: any;
  name: any;
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

class SolvedGene {
  name: any;
  constructor(gene) {
    this.name = gene;
  }
}

const solvedStatusLookup = model.getEnum('solvedStatus').map(
  e => new SolvedStatus(e.id, e.name));

const solvedGeneLookup = new Map();

class TgpStore {
  diagnosisLookup: {};
  families: Family[];
  familyLookup: {};
  hpoLookup: {};
  labs: Lab[];
  labLookup: {};
  loginUser: User;
  patients: Patient[];
  patientLookup: {};
  projects: Project[];
  projectLookup: Record<string, Project>;
  samples: Sample[];
  sampleLookup: {};
  uploads: Upload[];
  users: User[];
  userLookup: Record<string, User>;
  familyVariantStatusLookup: {};
  variantStatusFamilyLookup: {};
  constructor() {
    this.diagnosisLookup = {};
    this.families = [];
    this.familyLookup = {};
    this.hpoLookup = {};
    this.labs = [];
    this.labLookup = {};
    this.patients = [];
    this.patientLookup = {};
    this.projects = [];
    this.projectLookup = {};
    this.samples = [];
    this.sampleLookup = {};
    this.users = [];
    this.uploads = [];
    this.userLookup = {};
    this.familyVariantStatusLookup = {};
    this.variantStatusFamilyLookup = {};
  }

  get diagnosisEntities() {
    const diagnosisGroups = new Set();
    for (let pat of this.patients)
      for (let d of pat.diagnosisGroups) diagnosisGroups.add(d);
    return Object.values(this.diagnosisLookup)
      .concat(Object.values(this.hpoLookup))
      .concat(Array.from(diagnosisGroups));
  }

  get labsAndProjects() {
    // @ts-ignore
    return this.labs.concat(this.projects);
  }

  get sampleEntities() {
    // @ts-ignore
    return this.samples.concat(this.patients).concat(this.families);
  }

  get experimentTypes() {
    return Array.from(new Set(this.samples.map(s => s.experimentTypeText)));
  }

  get modesOfInheritance() {
    const modes = this.patients
      .filter(p => p.modeOfInheritance)
      .map(p => p.modeOfInheritance);
    return uniqueArray(modes);
  }

  get solvedStatuses() {
    const statuses = uniqueArray(this.families.map(f => f.solvedStatus));
    return solvedStatusLookup.filter(s => statuses.includes(s.id))
      .sort((a, b) => b.id - a.id);
  }

  get solvedGenes() {
    const geneSet = new Set(), result = [];
    for (let fam of this.families)
      for (let geneName of fam.solvedGeneNames)
        geneSet.add(geneName);
    for (let geneName of geneSet) 
      result.push(this.findSolvedGene(geneName));
    return result.sort((a, b) => a.name.localeCompare(b.name));
  }

  createOrRetrieveDiagnosis(diagnosisData) {
    if (diagnosisData.id in this.diagnosisLookup)
      return this.diagnosisLookup[diagnosisData.id];
    const dg = new Diagnosis(
      diagnosisData.id, diagnosisData.source, diagnosisData.code,
      diagnosisData.name
    );
    this.diagnosisLookup[dg.id] = dg;
    return dg;
  }

  createOrRetrieveHpo(hpoData) {
    if (hpoData.id in this.hpoLookup) return this.hpoLookup[hpoData.id];
    const hpo = new Hpo(
      hpoData.id, hpoData.code, hpoData.name, hpoData.group);
    this.hpoLookup[hpo.id] = hpo;
    return hpo;
  }

  addFamily(family) {
    this.families.push(family);
    this.familyLookup[family.id] = family;
  }

  createLab(
    labId: string, name: string, description: string, owner: User
  ) {
      const lab = this.addLab(labId, name, description, null, null);
      this.loginUser.labMembers.push(
        new LabMember(0, new Date(), lab))
  }

  removeLab(lab: Lab) {
      const members = lab.members;
      for (let m of members) m.removeLabMembership(lab);
      delete this.labLookup[lab.id];
      const idx = this.labs.findIndex(l => l == lab);
      this.labs.splice(idx, 1)
  }

  addLab(
    labId: string, name: string, description: string, affiliation: string,
    country: string
  ) {
    const lab = new Lab(labId, name, description, affiliation, country);
    this.labs.push(lab);
    this.labLookup[lab.id] = lab;
    return lab;
  }

  addLabUser(
    labId: string, userId: string, firstName: string, lastName: string,
    email: string, labUserRole: number, membershipDate: Date
  ) { 
    const lab = this.findLabById(labId);
    let user = this.findUserById(userId);
    if (!user) {
      user = new User(userId, email, firstName, lastName);
      this.addUser(user);
    }
    user.labMembers.push(new LabMember(labUserRole, membershipDate, lab));  
  }

  addPatient(patient) {
    this.patients.push(patient);
    this.patientLookup[patient.id] = patient;
  }

  createProject(
    projectId: string, name: string, description: string, owner: User
  ) {
      const project = this.addProject(projectId, name, description);
      this.loginUser.projectMembers.push(
        new ProjectMember(0, new Date(), project))
  }

  removeProject(project: Project) {
      const members = project.members;
      for (let m of members) m.removeProjectMembership(project);
      delete this.projectLookup[project.id];
      const idx = this.projects.findIndex(p => p == project);
      this.projects.splice(idx, 1)
  }

  addProject(
    projectId: string, name: string, description: string
  ) {
    const project = new Project(projectId, name, description);
    this.projects.push(project);
    this.projectLookup[project.id] = project;
    return project;
  }

  addProjectUser(
    projectId: string, userId: string, firstName: string, lastName: string,
    email: string, projectUserRole: number, membershipDate: Date
  ) { 
    const project = this.findProjectById(projectId);
    let user = this.findUserById(userId);
    if (!user) {
      user = new User(userId, email, firstName, lastName);
      this.addUser(user);
    }
    user.projectMembers.push(new ProjectMember(projectUserRole, membershipDate, project));  
  }

  addSample(sample) {
    this.samples.push(sample);
    this.sampleLookup[sample.id] = sample;
  }

  // Used only for testing purposes
  addUpload(
    id: string, name: string, description: string, fileTypeText: string,
    isApproved: boolean, isSubmitted: boolean, serverSampleCount: number,
    createdDateTimeEst: string, uploadComplete: boolean, labId: string,
    userId: string, metadata: string = null
  ) {
    const upload: Upload = new Upload(
      id, name, description, fileTypeText, isApproved, isSubmitted, serverSampleCount, createdDateTimeEst,
      uploadComplete, labId, userId, metadata, null);
    this.uploads.push(upload);
    return upload;
  }

  addUser(user: User) {
    this.users.push(user);
    this.userLookup[user.id] = user;
  }

  // Assign family object to each variant status, as this information is
  // not available when they are used with addVariantStatus
  assignVariantStatusFamilies() {
    for (let i in this.familyVariantStatusLookup) {
      const fam = this.findFamilyById(i);
      for (let j in this.familyVariantStatusLookup[i]) {
        for (let k of this.familyVariantStatusLookup[i][j].variantStatuses) {
          k.family = fam;
        } 
      }
    }
  }

  addVariantStatus(variantStatus) {
    const vfid = variantStatus.familyId;
    const vid = variantStatus.variantId;
    // populate familyVariantStatusLookup
    if (!(vfid in this.familyVariantStatusLookup))
      this.familyVariantStatusLookup[vfid] = {};
    if (!(vid in this.familyVariantStatusLookup[vfid]))
      this.familyVariantStatusLookup[vfid][vid] = new VariantStatusSet();
    this.familyVariantStatusLookup[vfid][vid].addVariantStatus(variantStatus);
    // populate variantStatusFamilyLookup
    if (!(vid in this.variantStatusFamilyLookup))
      this.variantStatusFamilyLookup[vid] = {};
    if (!(vfid in this.variantStatusFamilyLookup[vid]))
      this.variantStatusFamilyLookup[vid][vfid] =
        this.familyVariantStatusLookup[vfid][vid];
  }

  findDiagnosisById(diagnosisId) {
    return diagnosisId in this.diagnosisLookup ?
      this.diagnosisLookup[diagnosisId] : undefined;
  }

  findFamilyVariants(familyId) {
    return familyId in this.familyVariantStatusLookup ?
      this.familyVariantStatusLookup[familyId] : undefined;
  }

  findVariantStatusSet(variantId, familyId) {
    return familyId in this.familyVariantStatusLookup &&
      variantId in this.familyVariantStatusLookup[familyId] ?
      this.familyVariantStatusLookup[familyId][variantId] : undefined;
  }

  filterVariantStatusSets(variantId, familyId) {
    if (!(variantId in this.variantStatusFamilyLookup)) return {};
    const sets = {};
    for (let fid in this.variantStatusFamilyLookup[variantId]) {
      if (fid != familyId)
        sets[fid] = this.variantStatusFamilyLookup[variantId][fid];
    }
    return sets;
  }

  findHpoById(hpoId) {
    return hpoId in this.hpoLookup ?
      this.hpoLookup[hpoId] : undefined;
  }

  findLabById(labId: string) {
    return this.labs.find(l => l.id == labId);
  }

  findFamilyById(familyId) {
    return this.families.find(f => f.id == familyId);
  }

  findPatientById(patientId) {
    return this.patients.find(p => p.id == patientId);
  }

  findPatientByGenomicId(genomicStoreId) {
    return this.patients.find(p => p.genomicStoreId == genomicStoreId);
  }

  findProjectById(projectId: string) {
    return this.projects.find(p => p.id == projectId);
  }

  findUserById(userId: string): User {
    return this.users.find(u => u.id == userId);
  }

  findSolvedGene(geneName) {
    if (!solvedGeneLookup.has(geneName))
      solvedGeneLookup.set(geneName, new SolvedGene(geneName));
    return solvedGeneLookup.get(geneName);
  }

  findSolvedStatus(solvedStatusText: string) {
    return solvedStatusLookup.find(s => s.name == solvedStatusText);
  }

  findSampleById(sampleId: string) {
    return this.sampleLookup[sampleId];
    // return this.samples.find(s => s.id == sampleId);
  }
  
  // Used only for testing purposes  
  findUploadById(uploadId: string) {
    return this.uploads.find(u => u.id == uploadId);
  }

  removeDiagnosisIfUnused(diagnosis) {
    if (this.patients.some(p => p.hasAnyDiagnosis([diagnosis]))) return;
    delete this.diagnosisLookup[diagnosis.id];
  }

  removeHpoIfUnused(hpo) {
    if (this.patients.some(p => p.hasAnyDiagnosis([hpo]))) return;
    delete this.hpoLookup[hpo.id];
  }

  setLoginUser(userId: string) {
    this.loginUser = this.findUserById(userId);
  }

}

function uniqueArray(array) { return [...new Set(array)].sort(); }

const tgpStore = new TgpStore();
export default tgpStore;

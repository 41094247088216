import { Lab } from './groups';
import { User } from './user';
import tgpStore from '../tgpStore';
import Sample from './sample';

class Fastq {
  sample: Sample;
  file1: string;
  uri1: string;
  size1: Number;
  date1: Date;
  file2: string;
  uri2: string;
  size2: Number;
  date2: Date;

  constructor(
    sample: Sample,
    file1: string,
    uri1: string = null,
    size1: Number = null,
    date1: Date = null,
    file2: string,
    uri2: string = null,
    size2: Number = null,
    date2: Date = null
  ) {
    this.sample = sample;
    this.file1 = file1;
    this.uri1 = uri1;
    this.size1 = size1;
    this.date1 = date1;
    this.file2 = file2;
    this.uri2 = uri2;
    this.size2 = size2;
    this.date2 = date2;
  }

  get isFile1Uploaded(): boolean {
    return this.uri1 != null;
  }

  get isFile2Uploaded(): boolean {
    return this.uri2 != null;
  }

  get isUploadComplete(): boolean {
    return this.isFile1Uploaded && this.isFile2Uploaded;
  }
}

class VcfOrBam {
  sample: Sample;
  fileName: string;
  size: Number;
  uri: string;
  uploadDate: Date;

  constructor(
    sample: Sample,
    fileName: string,
    size: Number,
    uri: string,
    uploadDate: Date
  ) {
    this.sample = sample;
    this.fileName = fileName;
    this.size = size;
    this.uri = uri;
    this.uploadDate = uploadDate;
  }

  get isUploadComplete(): boolean {
    return this.uri != null;
  }
}

class Upload {
  createDate: Date;
  extraFiles: string[] = [];
  uploadSamples: any[] = [];
  lab: Lab;
  user: User;

  constructor(
    public id: string,
    public name: string,
    public description: string,
    public fileType: string,
    public isApproved: Boolean,
    public isSubmitted: Boolean,
    public serverSampleCount: Number,
    createDate: string,
    public uploadComplete: Boolean,
    labId: string,
    userId: string,
    public metadataFile: string = null,
    public metadataFileSize: Number = null
  ) {
    this.createDate = new Date(createDate);
    this.lab = tgpStore.labLookup[labId]
    this.user = tgpStore.findUserById(userId);
  }

  // Check fileType because an upload with bad ped/dat will nevertheless
  // populate those properties but filetype is derived from the samples,
  // implying successful ped/dat submission
  get missingMetadata(): boolean {
    return !(this.metadataFile && this.fileType);
  }

  get isUploadComplete(): boolean {
    return (
      !this.missingMetadata && this.uploadSamples.every(u => u.isUploadComplete)
    );
  }

  get sampleCount(): number {
    return this.uploadSamples.length;
  }

  get extraFileCount(): number {
    return this.extraFiles.length;
  }

  get hasExtraFiles(): boolean {
    return this.extraFileCount > 0;
  }

  get hasCompleteDetails(): boolean {
    return !!this.name && !!this.lab;
  }

  setDetails(details: any): void {
    this.name = details.name;
    this.description = details.description;
    this.lab = tgpStore.labLookup[details.labId]
  }

  syncSampleFastqs(data: any) {
    this.extraFiles = 'extraFiles' in data ? data.extraFiles : [];
    const uploadSamplesSynced: any[] = [];
    if ('samples' in data) {
      for (let samp of data.samples) {
        const sample: Sample = tgpStore.findSampleById(samp.sampleId);
        const uploadSample = this.newUploadSample(sample, samp);
        uploadSamplesSynced.push(uploadSample);
      }
    }
    this.uploadSamples = uploadSamplesSynced;
    return this;
  }

  newUploadSample(sample: Sample, data: any) {
    if (this.fileType == 'FASTQ') {
      const newSamp = new UploadFastqSample(this, sample);
      for (let uf of data.uploadFiles) {
        const newFile = new Fastq(
          sample,
          uf.file1,
          uf.uri1,
          uf.size1,
          uf.date1,
          uf.file2,
          uf.uri2,
          uf.size2,
          uf.date2
        );
        newSamp.files.push(newFile);
      }
      return newSamp;
    }
    else {
      const newSamp = new UploadVcfOrBamSample(this, sample);
      for (let uf of data.uploadFiles) {
        const newFile = new VcfOrBam(sample, uf.fileName, uf.size, uf.uri, uf.uploadDate);
        newSamp.files.push(newFile);
      }
      return newSamp;
    }
  }
}

class UploadSample {
  upload: Upload;
  sample: Sample;
  constructor(upload: Upload, sample: Sample) {
    this.upload = upload;
    this.sample = sample;
  }
}

class UploadFastqSample extends UploadSample {
  files: Fastq[];
  constructor(upload: Upload, sample: Sample) {
    super(upload, sample);
    this.files = [];
  }

  get fileCount(): number {
    return this.files.length * 2;
  }

  get uploadedFileCount(): number {
    return this.files.reduce(
      (upcount, file) =>
        upcount +
        (file.isFile1Uploaded ? 1 : 0) +
        (file.isFile2Uploaded ? 1 : 0),
      0
    );
  }

  get isUploadComplete(): boolean {
    return this.files.every(u => u.isUploadComplete);
  }

}

class UploadVcfOrBamSample extends UploadSample {
  files: VcfOrBam[];
  constructor(upload: Upload, sample: Sample) {
    super(upload, sample);
    this.files = [];
  }

  get fileCount(): number {
    return this.files.length;
  }

  get uploadedFileCount(): number {
    return this.files.reduce(
      (upcount, file) => upcount + (file.isUploadComplete ? 1 : 0),
      0
    );
  }

  get isUploadComplete(): boolean {
    return this.files.every(u => u.isUploadComplete);
  }

}

function deserializeUploadList(data: any[]): Upload[] {
  return data.map(
    u =>
      new Upload(
        u.id,
        u.name,
        u.description,
        u.fileType,
        u.isApproved,
        u.isSubmitted,
        u.sampleCount,
        u.createdDateTime,
        u.uploadComplete,
        u.labId,
        u.userId,
        u.metadatapath ? basename(u.metadatapath) : null,
        u.metadatapathSize,
      )
  );
}

// Reference: https://stackoverflow.com/a/15270931/2117491
function basename(path) {
  return path.split('/').reverse()[0];
}

interface SampleStatusFile {
  fileName: string;
  size: number;
  md5Sum: string | null;
}

class SampleStatusSummary {
  private samples: SampleStatus[];

  constructor(samples: SampleStatus[]) {
    this.samples = samples;
  }

  get notFailedSamples(): SampleStatus[] {
    return this.samples.filter(s => s.uploadDashboardStatus !== "Incomplete");
  }

  get failedSamples(): SampleStatus[] {
    return this.samples.filter(s => s.uploadDashboardStatus === "Incomplete");
  }
  
  get totalSampleCount(): number {
    return this.samples.length;
  }

  get pendingSampleCount(): number {
    return this.samples.filter(s => s.uploadDashboardStatus === "Pending").length;
  }

  get completeSampleCount(): number {
    return this.samples.filter(s => s.uploadDashboardStatus === "Complete").length;
  }

  get failedSampleCount(): number {
    return this.samples.filter(s => s.uploadDashboardStatus === "Incomplete").length;
  }
}

interface SampleStatus {
  labName: string;
  familyExternalId: string;
  patientExternalId: string;
  externalSampleAliquot: string;
  importedAt: Date | null;
  uploadDashboardStatus: string;
  incompleteReason: string | null;
  files: SampleStatusFile[];
}

function deserializeSampleStatus(jsonData: any): SampleStatusSummary {
  const sampleStatuses = jsonData.map((sample: any) => {
    const files = [];
    if (sample.uploadDashboardStatus == "Incomplete") {
      if (sample.uploadFastqFiles) {
        for (const fastqFile of sample.uploadFastqFiles) {
            files.push({
              fileName: fastqFile.file1,
              size: fastqFile.size1,
              md5Sum: fastqFile.md5Read1 || null,
            });
            files.push({
              fileName: fastqFile.file2,
              size: fastqFile.size2,
              md5Sum: fastqFile.md5Read2 || null,
            });
        }
      } else if (sample.uploadVcfOrBamFiles) {
        for (const vcfOrBamFile of sample.uploadVcfOrBamFiles) {
          files.push({
            fileName: vcfOrBamFile.fileName,
            size: vcfOrBamFile.size,
            md5Sum: vcfOrBamFile.md5VcfOrBam || null,
          });
        }
      }
    }

    return {
      labName: sample.labName,
      familyExternalId: sample.familyExternalId,
      patientExternalId: sample.patientExternalId,
      externalSampleAliquot: sample.externalSampleAliquot,
      importedAt: sample.importedAt ? new Date(sample.importedAt) : null,
      uploadDashboardStatus: sample.uploadDashboardStatus,
      incompleteReason: sample.incompleteReason,
      files: files,
    };
  });

  return new SampleStatusSummary(sampleStatuses);
}

export {Upload, Fastq, UploadSample, deserializeSampleStatus, deserializeUploadList, SampleStatusSummary};
